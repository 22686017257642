<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="info"
    >
      <h4 class="alert-heading">
        Erreur lors de la récupération des données de facturation
      </h4>
      <div class="alert-body">
        Aucune facture trouvée avec cet identifiant de facture. Vérifiez
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list' }"
        >
          la liste des factures
        </b-link>
        pour d'autres factures.
      </div>
    </b-alert>

    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <b-img
                    :src="appLogoImage"
                    width="40"
                    height="40"
                    alt="logo"
                  />

                  <h2 class="brand-text invoice-logo text-primary">
                    {{ appName }}
                  </h2>
                </div>
                <p class="card-text mb-25">
                  {{ devis.transporteur === null ? devis.societe.adresse : devis.transporteur.adresse }}
                </p>
                <p class="card-text mb-25">
                  {{ devis.transporteur === null ? devis.societe.email : devis.transporteur.email }}
                </p>
                <p class="card-text mb-0">
                  {{ devis.transporteur === null ? devis.societe.telephone : devis.transporteur.telephone }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Devis n°
                  <span class="invoice-number">{{ devis.code }}</span>
                </h4>
                <div class="">
                  <p class="invoice-date-title">
                    Devis du:
                  </p>
                  <p class="invoice-date">
                    {{ devis.created_at }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2 text-primary">
                  Devis à:
                </h6>
                <h6 class="mb-25">
                  {{ devis.affreteur.firstname }} {{ devis.affreteur.lastname }}
                </h6>
                <p class="card-text mb-25">
                  {{ devis.affreteur.adresse }}
                </p>
                <p class="card-text mb-25">
                  {{ devis.affreteur.telephone }}
                </p>
                <p class="card-text mb-25">
                  {{ devis.affreteur.email }}
                </p>

              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2 text-primary">
                    Camion :
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Moyen de Transport:
                        </td>
                        <td><span class="font-weight-bold">{{ devis.camion_type.libelle }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          N° Chassis:
                        </td>
                        <td>{{ devis.moyen_transport.num_chassis }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          N° d'immatriculation:
                        </td>
                        <td>{{ devis.moyen_transport.num_immatriculation }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Nombre Essieux:
                        </td>
                        <td>{{ devis.moyen_transport.nombre_essieux }}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="devis.transporteur === null"
              class="invoice-spacing"
            >

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2 text-primary">
                  Transporteur :
                </h6>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        Nom et Prénoms
                      </td>
                      <td><span class="font-weight-bold">{{ devis.transporteur_interne.prenom }} {{ devis.transporteur_interne.nom }}</span></td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Téléphone
                      </td>
                      <td>{{ devis.transporteur_interne.telephone }}</td>
                    </tr>
                  </tbody>
                </table>

              </b-col>
              <b-col
                cols="12"
                xl="6"
                class=" p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <table class="mt-3">
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        Catégorie Permis
                      </td>
                      <td><span class="font-weight-bold">{{ devis.transporteur_interne.permis }}</span></td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        N° Permis
                      </td>
                      <td>{{ devis.transporteur_interne.numero_permis }}</td>
                    </tr>

                  </tbody>
                </table>

              </b-col>
            </b-row>
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2 text-primary">
                  Offre de fret :
                </h6>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        Code
                      </td>
                      <td><span class="font-weight-bold">{{ devis.offre.code }}</span></td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Publication
                      </td>
                      <td>{{ devis.offre.created_at }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Adresse Départ
                      </td>
                      <td>{{ devis.ville_depart }} ( {{ devis.departement_depart }} )</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Adresse Destination
                      </td>
                      <td>{{ devis.ville_destination }} ( {{ devis.departement_destination }} )</td>
                    </tr>
                  </tbody>
                </table>

              </b-col>
              <b-col
                cols="12"
                xl="6"
                class=" p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <table class="mt-3">
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        Date Départ
                      </td>
                      <td><span class="font-weight-bold">{{ devis.offre.date_prev_depart }}</span></td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Date Arrivée
                      </td>
                      <td>{{ devis.offre.date_prev_livraison }}</td>
                    </tr>

                    <tr>
                      <td class="pr-1">
                        Poids
                      </td>
                      <td>{{ devis.offre.poids_marchandise }} {{ devis.unite_de_mesure }}</td>
                    </tr>
                  </tbody>
                </table>

              </b-col>
              <b-col
                cols="12"
                class="p-0"
              >
                <table class="mt-3">
                  <tbody>
                    <tr>
                      <td class="pr-1 text-primary">
                        Marchandise :
                      </td>
                      <td>{{ devis.marchandise }}</td>
                    </tr>
                  </tbody>
                </table>

              </b-col>

            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceDescription"
            :fields="[{ key: 'description', label: 'Description' }, { key: 'date_prev_depart', label: 'Date Départ Prév' }, { key: 'date_prev_livraison', label: 'Date Arrivée Prév' }, { key: 'montant', label: 'Total' }]"
          />
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Devis émis par :</span>
                  <span class="ml-75">{{ devis.transporteur === null ? devis.societe.lastname + ' ' + devis.societe.firstname : devis.transporteur.lastname + ' ' + devis.transporteur.firstname }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total HT :
                    </p>
                    <p
                      class="invoice-total-amount"
                      v-text="`${devis.montant.toLocaleString('fr-FR', { maximumFractionDigits: 2 })} ${devis.devise}`"
                    />
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      TVA ( 18% ) :
                    </p>
                    <p
                      class="invoice-total-amount"
                      v-text="`${devis.tva.toLocaleString('fr-FR', { maximumFractionDigits: 2 }) } ${devis.devise}`"
                    />
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total TTC :
                    </p>
                    <p class="invoice-total-amount">
                      {{ devis.total_ttc.toLocaleString('fr-FR', { maximumFractionDigits: 2 }) }} {{ devis.devise }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
              projects. Thank You!</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            size="sm"
            block
            @click="printInvoice"
          >
            <feather-icon icon="PrinterIcon" />
            Imprimer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            class="mb-75"
            size="sm"
            block
            onclick="history.back()"
          >
            <feather-icon icon="ArrowLeftCircleIcon" />
            Retour
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>

    <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment />
  </section>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'

import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BImg,
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const invoiceDescription = ref([])
    const {
      getDevisByCode, devis,
    } = useSocieteTransporteurs()
    const { appName, appLogoImage } = $themeConfig.app
    onMounted(async () => {
      await getDevisByCode()
      invoiceDescription.value = [
        {
          description: 'Frais d\'expédition',
          date_prev_depart: devis.value.date_prev_depart,
          date_prev_livraison: devis.value.date_prev_livraison,
          montant: `${devis.value.montant} ${devis.value.devise}`,
        },
      ]
      console.clear()
    })

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
      devis,

      appName,

      appLogoImage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
